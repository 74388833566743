import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { debounce } from 'lodash';
import { Eye, EyeOff, Lock, AtSign, User, Search } from 'lucide-react';
import liff from '@line/liff';
import Logo from '../assets/fa-sme-logo.svg';
import bg_circle from '../assets/bg-circle-login.svg';
import bg_circle_under from '../assets/bg-circle-login-under.svg';

// Interfaces
interface ProfileUser {
  userId: string;
  displayName: string;
  pictureUrl: string;
}

interface FormState {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface UserData {
  uuid: string;
  name: string;
  email: string;
  picture: string;
}

interface OptionType {
  value: string;
  label: string;
}

// Custom Components
const DropdownIndicator = () => <Search className='h-5 w-5 text-[#1226C3]' />;

const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#FFFFFF',
    border: 'none', // Removes border
    borderRadius: '9999px',
    minHeight: '40px',
    width: '100%', // Fixed typo: 'with' to 'width'
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    boxShadow: state.isFocused ? '0 0 0 1px rgba(18, 38, 195, 0.5)' : 'none', // Applies shadow only when focused
    outline: 'none', // Removes outline
    transition: 'box-shadow 0.2s ease', // Smooth transition for box-shadow
    '&:hover': {
      boxShadow: '0 0 0 1px rgba(18, 38, 195, 0.5)', // Keeps shadow on hover
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: 0,
    marginLeft: 'auto',
    color: '#FFFFFF',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  singleValue: (provided: any) => ({ ...provided, color: 'black' }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#FFFFFF',
    color: 'black',
  }),
  menuList: (provided: any) => ({ ...provided, color: 'black' }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: 'black',
    backgroundColor: state.isSelected ? '#FFFFFF' : '#f7f7f7',
    ':hover': { backgroundColor: '#d0d0d0' },
  }),
};



const InputField: React.FC<{
  id: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  icon: React.ReactNode;
  ariaLabel: string;
  toggleVisibility?: () => void;
  showVisibility?: boolean;
  className?: string;
}> = ({ id, type, value, onChange, placeholder, icon, ariaLabel, toggleVisibility, showVisibility, className }) => (
  <div>
    <label htmlFor={id} className='block text-sm text-[#ED1D22]'>{placeholder}</label>
    <div className={`flex h-[40px] items-center gap-2 rounded-full px-3 py-2 ${className}`}>
      {icon}
      <input
        id={id}
        type={toggleVisibility ? (showVisibility ? 'text' : 'password') : type}
        value={value}
        onChange={onChange}
        className='grow text-gray-800 border-none outline-none'
        placeholder={placeholder}
        aria-label={ariaLabel}
        required
      />
      {toggleVisibility && (
        <button
          type='button'
          onClick={toggleVisibility}
          className='flex items-center justify-center p-2'
          aria-label={showVisibility ? 'Hide password' : 'Show password'}
        >
          {showVisibility ? <Eye className='h-5 w-5 text-gray-600' /> : <EyeOff className='h-5 w-5 text-[#1226C3]' />}
        </button>
      )}
    </div>
  </div>
);

const Linkacc: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formState, setFormState] = useState<FormState>({ name: '', email: '', password: '', confirmPassword: '' });
  const [error, setError] = useState<string | null>(null);
  const [profile, setProfile] = useState<ProfileUser | null>(null);
  const [data, setData] = useState<OptionType[]>([]);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  useEffect(() => {
    const initializeLiff = async () => {
      try {
        await liff.init({ liffId: '2006325459-O47R7K6l' });
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          const profileUser = await liff.getProfile();
          if (profileUser) {
            setProfile({
              userId: profileUser.userId,
              displayName: profileUser.displayName,
              pictureUrl: profileUser.pictureUrl || '',
            });
          } else {
            setError('Unable to retrieve profile information.');
          }
        }
      } catch (err) {
        console.error('Error initializing LIFF:', err);
        setError('An error occurred initializing LIFF.');
      }
    };

    initializeLiff();
  }, []);

  // Debounced function
  const fetchData = useCallback(async (query: string) => {
    if (!query) return;
    try {
      const response = await axios.get(`https://api-diprom.adcm.co.th/api/user/search?q=${query}`);
      console.log('Search API response:', response.data);
      if (response.data?.response_data?.data) {
        const fetchedData: OptionType[] = response.data.response_data.data.map((user: UserData) => ({
          value: user.uuid,
          label: user.name,
        }));
        setData(fetchedData);
      } else {
        console.warn('Unexpected data format:', response.data);
        setError('Unexpected data format received.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('An error occurred while fetching data. Check console for details.');
    }
  }, []);

  // Create a debounced version of fetchData
  const debouncedFetchData = useMemo(() => debounce((query: string) => fetchData(query), 300), [fetchData]);

  const handleSearchInputChange = (inputValue: string) => {
    debouncedFetchData(inputValue);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [id]: value }));
  };

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword((prev) => !prev);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formState.password !== formState.confirmPassword) {
      setError('รหัสผ่านไม่ตรงกัน กรุณาลองใหม่อีกครั้ง');
      return;
    }
    try {
      const response = await axios.put(`https://api-diprom.adcm.co.th/api/user/${selectedOption?.value}`, {
        name: selectedOption?.label,
        email: formState.email,
        password: formState.password,
        line_user_id: profile?.userId,
        line_user_name: profile?.displayName,
      });
      if (response.status === 200) {
        alert('บันทึกข้อมูลเรียบร้อยแล้ว');
      } else {
        setError('Failed to save data');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const responseCode = error.response?.data?.response_code;
        const responseMessage = error.response?.data?.response_message;
        if (responseCode === 400) {
          alert(responseMessage === 'User has already connect'
            ? 'ผู้ใช้นี้ได้ทำการผูกไปแล้ว'
            : 'อีเมลนี้ได้ทำการผูกไปแล้ว'
          );
        } else {
          setError('An error occurred while saving data. Please check your connection.');
        }
      } else {
        setError('An unexpected error occurred. Please try again later.');
      }
    }
  };

  const handleNameChange = async (selectedOption: OptionType | null) => {
    setSelectedOption(selectedOption);
    if (selectedOption) {
      try {
        const searchQuery = selectedOption.label;
        const response = await axios.get(`https://api-diprom.adcm.co.th/api/user/?search=${searchQuery}`);
        console.log('Search API response:', response.data);
        if (response.data?.response_data?.data) {
          const fetchedData: OptionType[] = response.data.response_data.data.map((user: UserData) => ({
            value: user.uuid,
            label: user.name,
          }));
          setData(fetchedData);
        } else {
          console.warn('Unexpected data format:', response.data);
          setError('Unexpected data format received.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('An error occurred while fetching data. Check console for details.');
      }
    }
  };

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-[linear-gradient(180deg,_#ED1D22_0%,_#1226C3_43.2%)] relative'>
      <img src={bg_circle} alt='background circle' className='absolute top-0 right-0 w-[40vw] max-w-[300px]' />
      <div className='p-4 sm:p-8 rounded-lg w-full max-w-md text-white relative z-10'>
        <div className='flex flex-col w-full justify-center items-center mb-6 space-y-6'>
          <img src={Logo} alt='logo' className='w-32 h-32' />
          <div className='w-full text-4xl text-center font-bold'>Business Matching</div>
        </div>
        <div className='text-xl sm:text-2xl mb-6 text-center text-[#B8B8B8] font-thin'>
          กรุณาเข้าสู่ระบบของคุณ
        </div>
        {error && <div className='text-red-500 text-center mb-4 font-bold'>{error}</div>}
        <form className='space-y-4' onSubmit={handleSubmit}>
          <div>
            <label htmlFor='name' className='block text-sm text-[#ED1D22]'>ชื่อ-นามสกุล</label>
            <div className='flex h-[40px] w-full items-center gap-2 bg-[#FFFFFF] rounded-full px-3 py-2'>
              <User className='h-5 w-5 text-[#1226C3]' />
              <div className='w-full'>
                <Select
                  options={data}
                  placeholder="กรุณากรอกชื่อ-นามสกุลของคุณ"
                  styles={customSelectStyles}
                  isClearable
                  isSearchable
                  onInputChange={handleSearchInputChange}
                  onChange={handleNameChange}
                  value={selectedOption}
                  components={{ DropdownIndicator }}
                />
              </div>
            </div>
          </div>

          <InputField
            id='email'
            type='email'
            value={formState.email}
            onChange={handleChange}
            placeholder='กรุณากรอกอีเมลของคุณ'
            icon={<AtSign className='h-5 w-5 text-[#1226C3]' />}
            ariaLabel='Email'
            className='bg-[#FFFFFF]'
          />

          <InputField
            id='password'
            type='password'
            value={formState.password}
            onChange={handleChange}
            placeholder='กรุณากรอกรหัสผ่านของคุณ'
            icon={<Lock className='h-5 w-5 text-[#1226C3]' />}
            ariaLabel='Password'
            toggleVisibility={togglePasswordVisibility}
            showVisibility={showPassword}
            className='bg-[#FFFFFF]'
          />

          <InputField
            id='confirmPassword'
            type='password'
            value={formState.confirmPassword}
            onChange={handleChange}
            placeholder='กรุณากรอกยืนยันรหัสผ่าน'
            icon={<Lock className='h-5 w-5 text-[#1226C3]' />}
            ariaLabel='Confirm Password'
            toggleVisibility={toggleConfirmPasswordVisibility}
            showVisibility={showConfirmPassword}
            className='bg-[#FFFFFF]'
          />

          <button
            type='submit'
            className='w-full bg-[#ED1D22] py-2 rounded-full text-white text-lg font-bold'
          >
            ผูกบัญชี
          </button>
        </form>
      </div>
      <img src={bg_circle_under} alt='background circle' className='absolute bottom-0 left-0 w-[50vw] max-w-[300px]' />
    </div>
  );
};

export default Linkacc;
