import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
// import Login from './page/login';
// import Register from './page/register';
import Linkacc from './page/linkacc';


const App = () => {
  const style = {
    fontFamily: 'Prompt, sans-serif'
  };
  return (
    <div style={style}> 
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Login />} /> */}
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path='/' element={<Linkacc />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
